import { Autocomplete, Box, Card, CardContent, CardMedia, CircularProgress, Container, List, ListItem, ListItemText, Paper, TextField, Typography } from '@mui/material'
import { useEffect, useState, type FunctionComponent } from 'react'
import Section from '../components/Section'
import { useGetAttendants } from '../hooks/use.get.attendants'
import { type Attendant } from '../models/attendant.model'
import { type Table as TableModel } from '../models/table.model'
import { useGetTables } from '../hooks/use.get.tables'
import { Group } from '@mui/icons-material'

const Table: FunctionComponent = () => {
  const { isLoading, getAttendants } = useGetAttendants()
  const { isLoading: tablesLoading, getTables } = useGetTables()
  const [attendants, setAttendants] = useState<Attendant[]>([])
  const [tables, setTables] = useState<TableModel[]>([])
  const [selectedAttendant, setSelectedAttendant] = useState<Attendant | null>(null)
  const [selectedTable, setSelectedTable] = useState<TableModel | null>(null)
  const fetchData = async (): Promise<void> => {
    const [att, tab] = await Promise.all([getAttendants(), getTables()])
    att.sort((a, b) => {
      const sameTable = a.table === b.table
      if (a.table > b.table || (sameTable && a.name > b.name)) {
        return 1
      }
      if (a.table < b.table || (sameTable && a.name < b.name)) {
        return -1
      }
      return 0
    })
    setAttendants(att)
    setTables(tab)
  }
  useEffect(() => { fetchData() }, [])
  return (
    <Section sx={{ color: 'black' }}>
      <Container sx={{
        mt: 10,
        mb: 10,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}>
        <Typography align="center" variant="h4" sx={{ mb: 2 }}>
          Buscar mesa
        </Typography>
        <Typography align="center" variant="h6" sx={{ mb: 2 }}>
          Ingresa tu nombre para buscar tu mesa
        </Typography>
        {
          (isLoading || tablesLoading)
            ? <CircularProgress />
            : <Autocomplete
                disablePortal
                options={attendants}
                getOptionLabel={x => x.name}
                isOptionEqualToValue={
                  (option, value) => option.name === value.name
                }
                groupBy={x => x.table}
                sx={{ width: 300 }}
                onChange={(e, value) => {
                  setSelectedAttendant(value)
                  setSelectedTable(
                    tables.find(x => x.name === value?.table) ?? null
                  )
                }}
                renderInput={
                  (params) => <TextField {...params} label="Nombre" />
                } />
        }
        {
          selectedAttendant &&
            <Card sx={{ maxWidth: 800, margin: '2rem auto' }}>
              <CardMedia
                component="img"
                image={selectedTable?.imageUrl}
                sx={{ maxHeight: '300px', maxWidth: '100%', objectFit: 'contain', p: 1 }} />
              <CardContent>
                <Typography variant="h4" component="h1" gutterBottom>
                  {selectedTable?.name}
                </Typography>
                <Typography variant="body1" color="text.secondary" paragraph>
                  {selectedTable?.description}
                </Typography>

                <Paper elevation={2} sx={{ mt: 3, p: 2 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Group sx={{ mr: 1 }} />
                    <Typography variant="h6">Personas en esta mesa</Typography>
                  </Box>
                  <List>
                    {attendants.filter(x => x.table === selectedAttendant.table).map((member) => (
                      <ListItem key={member.id}>
                        <ListItemText primary={member.name} />
                      </ListItem>
                    ))}
                  </List>
                </Paper>
              </CardContent>
            </Card>
        }
      </Container>
    </Section>
  )
}

export default Table
